const listFlows = () => {
	return `query listFlow {
		listFlow {
			year
			start_date
			end_date
			type
			_id
		}
	}`;
}

export default listFlows;
