const HRFinishFlow = () => {
	return `mutation HRFlowComplete(
		$appraisee_id: String!
		$flowType: String!
		$pendency: String
		$year: Int
	) {
		hrFlowComplete(
			appraisee_id: $appraisee_id
			flowType: $flowType
			pendency: $pendency
			year: $year
		)
	}`;
}

export default HRFinishFlow;
